import React, { useState, useEffect } from "react";
import TheNav from "../Components/navbar";
import CreatorOrders from "../Components/creator/OrdersTable";
import EditorOrders from "../Components/editor/OrdersTable";
import { useAuth } from "../Context/AuthContext";
import Loader from "../Components/Loader";

const Orders = () => {
  const { activeUser } = useAuth();
  const [orders, setOrders] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (activeUser) {
      setOrders(activeUser.orders || {}); // Set to empty object if orders is null or undefined
      setLoading(false);
    }
  }, [activeUser]);

  return (
    <div>
      <TheNav />
      <div className="max-w-7xl mx-auto">
        {loading ? (
          <Loader />
        ) : activeUser?.profileInfo?.role.toLowerCase() === "creator" ? (
          <CreatorOrders orders={orders} />
        ) : (
          <EditorOrders orders={orders} user={activeUser} setOrders={setOrders} />
        )}
      </div>
    </div>
  );
};

export default Orders;
