import React from 'react';

export default function Cookies() {
    return (
        <div className="container mx-auto px-4 py-10">
            <h1 className="text-4xl font-bold text-center mb-6">Cookies Policy</h1>

            <p>This Cookies Policy explains how Kre8 Content, LLC. ("Kre8", "we", "us", or "our") uses cookies and similar technologies when you visit or interact with our website. By using our services, you consent to the use of cookies as outlined in this policy.</p>

            <h2 className="text-2xl font-semibold mt-6">1. What Are Cookies?</h2>
            <p>Cookies are small text files that are placed on your device (e.g., computer, smartphone, tablet) when you visit a website. Cookies allow websites to remember your preferences and track information about your usage for purposes like authentication and analytics.</p>

            <h2 className="text-2xl font-semibold mt-6">2. Types of Cookies We Use</h2>
            <p>We use the following types of cookies on Kre8:</p>
            <ul className="list-disc ml-8 mt-2">
                <li><strong>Strictly Necessary Cookies:</strong> These cookies are essential for the operation of our website and services. They enable functions such as user authentication and session management.</li>
                <li><strong>Analytics Cookies:</strong> We use analytics cookies to understand how users interact with our website, allowing us to improve the performance and usability of our platform. These cookies collect anonymous data about website usage.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6">3. Third-Party Cookies</h2>
            <p>We use third-party cookies from services like Google Analytics to help us analyze website traffic and usage patterns. Google Analytics collects anonymous data to provide insights into how our users interact with the website. These cookies help us improve our platform by monitoring performance and user behavior.</p>

            <h2 className="text-2xl font-semibold mt-6">4. Cookie Duration</h2>
            <p>The cookies we use on Kre8 are session cookies, meaning they are temporary and only remain on your device for the duration of your session. Once you close your browser or log out, the session cookies are automatically deleted from your device.</p>

            <h2 className="text-2xl font-semibold mt-6">5. Managing and Disabling Cookies</h2>
            <p>You have the option to manage or disable cookies by adjusting your browser settings. Please note that disabling certain cookies may affect the functionality of our website, and you may not be able to access some features or services.</p>
            <ul className="list-disc ml-8 mt-2">
                <li>For information on how to manage cookies in common browsers, visit your browser's help section.</li>
                <li>You can opt-out of Google Analytics tracking by visiting the <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer" className="text-indigo-600 underline">Google Analytics Opt-out Browser Add-on</a>.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6">6. Changes to This Cookies Policy</h2>
            <p>We may update this Cookies Policy from time to time. Any changes will be posted on this page, and your continued use of the platform after any changes indicates your acceptance of the new policy.</p>

            <p className="mt-6">If you have any questions about our use of cookies, please contact us at support@kre8.com.</p>
        </div>
    );
}
