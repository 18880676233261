import React, { useEffect, useState } from 'react';
import { db, auth } from '../Context/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const ChatSidebar = ({ conversations, onSelectConversation }) => {
    const [userDetails, setUserDetails] = useState({});
    const currentUser = auth.currentUser;

    useEffect(() => {
        const fetchUserDetails = async () => {
            if (!currentUser) return;

            let details = { ...userDetails };

            for (let conversation of conversations) {
                const friendId = conversation.userId === currentUser.uid ? conversation.friendId : conversation.userId;
                //friendId works
                if (!details[friendId]) {
                    const userDoc = await getDoc(doc(db, 'Users', friendId));
                    if (userDoc.exists()) {
                        // alert("EXISTS")
                        //user exists
                        details[friendId] = userDoc.data();
                        console.log(`Profile Info for ${friendId}:`, details[friendId].profileInfo);
                    }
                }
            }
            setUserDetails(details);
        };

        fetchUserDetails();
    }, [conversations, currentUser]);

    return (
        <div className="flex flex-col py-8 pl-6 pr-2 w-64 bg-white flex-shrink-0">
            <div className="flex flex-row items-center justify-center h-12 w-full">
                <div className="ml-2 font-bold text-2xl">All Messages</div>
            </div>
            <div className="flex flex-col mt-8">
                <div className="flex flex-row items-center justify-between text-xs">
                    <span className="font-bold">Active Conversations</span>
                    <span className="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full">{conversations.length}</span>
                </div>
                <div className="flex flex-col space-y-1 mt-4 -mx-2 h-48 overflow-y-auto">
                    {conversations.map((conversation, index) => {
                        const friendId = conversation.userId === currentUser.uid ? conversation.friendId : conversation.userId;
                        const friendDetails = userDetails[friendId] || {};

                        return (
                            <button
                                key={index}
                                className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2"
                                onClick={() => onSelectConversation(conversation.id)}
                            >
                                <img src={friendDetails.profileInfo?.profilePicUrl || ''} alt="profile" className="flex items-center justify-center h-8 w-8 bg-indigo-200 rounded-full" />
                                <div className="ml-2 text-sm font-semibold">@{friendDetails.profileInfo?.username || 'Loading...'}</div>
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ChatSidebar;
