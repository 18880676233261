import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore'; // Import necessary Firestore functions
import { db } from '../Context/firebaseConfig'; // Import your Firestore config
import '../CSS/tailwind.css';
import TheNav from '../Components/navbar';

const GameOfChoice = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategoriesWithEditors = async () => {
            try {
                const categoriesCollection = collection(db, 'Categories');
                const categoriesSnapshot = await getDocs(categoriesCollection);

                const categoriesList = await Promise.all(categoriesSnapshot.docs.map(async (categoryDoc) => {
                    const categoryData = categoryDoc.data();

                    // Query the Users collection for editors with this category as their mainGame
                    const editorsQuery = query(
                        collection(db, 'Users'),
                        where('profileInfo.role', '==', 'Editor'),
                        where('profileInfo.mainGame', '==', categoryData.categoryName)
                    );
                    const editorsSnapshot = await getDocs(editorsQuery);

                    // Count the number of matching editors
                    const numberOfEditors = editorsSnapshot.empty ? 0 : editorsSnapshot.size;

                    return {
                        id: categoryDoc.id,
                        categoryName: categoryData.categoryName,
                        categoryImage: categoryData.categoryImage,
                        numberOfEditors: numberOfEditors,
                    };
                }));

                setCategories(categoriesList);
            } catch (error) {
                console.error("Error fetching categories and editors: ", error);
            }
        };

        fetchCategoriesWithEditors(); // Only call this function to fetch categories and count editors
    }, []);

    return (
        <>
            <TheNav />
            <p className="text-4xl font-bold text-gray-900 dark:text-white" style={{ textAlign: "center", marginTop: "30px", marginBottom: "30px" }}>Categories</p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-4 gap-4">
                {categories.map((category) => (
                    <Link to={`/editors/${category.id}`} key={category.id}>
                        <div style={{ marginTop: "20px", margin: "auto", marginBottom: "10%" }} className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                            <img src={category.categoryImage} alt={category.categoryName} style={{ margin: "auto" }} />
                            <div className="p-5">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ textAlign: "center" }}>{category.categoryName}</h5>
                                <div className="flex justify-center">
                                    <h3>{category.numberOfEditors} Editors</h3>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    );
}

export default GameOfChoice;
