import { useEffect } from 'react';
import { getToken } from "firebase/messaging";
import { messaging } from "../Context/firebaseConfig";
import { setUserCMFToken } from "../api/user";

export const useNotificationPermission = () => {
    

    useEffect(() => {
        const requestPermission = async () => {
            console.log("Requesting permission...");
            try {
                const permission = await Notification.requestPermission();
                if (permission === "granted") {
                    const generatedToken = await getToken(messaging, {
                        vapidKey: process.env.REACT_APP_VAPID_KEY,
                    });
                    console.log("Token generated successfully:");
                    await setUserCMFToken(generatedToken);
                } else if (permission === "denied") {
                    console.log("Notification permission denied");
                }
            } catch (error) {
                console.error("An error occurred while requesting permission:", error);
            }
        };

        requestPermission();
    }, []);
};

export default useNotificationPermission;