import React from "react";
import TheNav from "../Components/navbar";
import EditorProfileScreen from "../Components/viewEditorProfileScreen";
import { useParams } from 'react-router';

const viewEditorProfile = () => {
    const { editorId } = useParams();

    return (
        <>
            <TheNav />
            <EditorProfileScreen editorName={editorId} />
        </>
    );
};

export default viewEditorProfile;
