import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, where, getDocs, limit, startAfter } from 'firebase/firestore';
import { db } from '../Context/firebaseConfig';
import TheNav from '../Components/navbar';
import debounce from 'lodash.debounce'; // Make sure to install lodash.debounce with `npm install lodash.debounce`

export default function ViewCreators() {
    const [creators, setCreators] = useState([]);
    const [lastDoc, setLastDoc] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const LIMIT = 8;

    const fetchCreators = async () => {
        setLoading(true);
        let q = query(collection(db, "Users"), where("profileInfo.role", "==", "Creator"), limit(LIMIT));

        if (lastDoc) {
            q = query(q, startAfter(lastDoc));
        }

        try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const fetchedCreators = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Ensure unique creators only by filtering out duplicates
                setCreators(prev => [
                    ...prev,
                    ...fetchedCreators.filter(newCreator =>
                        !prev.some(existingCreator => existingCreator.id === newCreator.id)
                    )
                ]);

                setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

                if (querySnapshot.docs.length < LIMIT) {
                    setHasMore(false);
                }
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Failed to fetch creators:", error);
        } finally {
            setLoading(false);
        }
    };

    // Debounced Scroll Handler
    const handleScroll = useCallback(
        debounce(() => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100 && !loading && hasMore) {
                fetchCreators();
            }
        }, 200), // Adjust delay as needed
        [loading, hasMore]
    );

    useEffect(() => {
        fetchCreators(); // Initial fetch
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <>
            <TheNav />
            <p className="text-4xl text-gray-900 font-bold dark:text-white" style={{ textAlign: "center", marginTop: "30px" }}>
                Available Creators
            </p>
            <div className="bg-white">
                <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                    <h2 className="sr-only">Products</h2>
                    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-4 xl:gap-x-8">
                        {creators.map((creator) => (
                            <Link to={`/creator/${creator.profileInfo.username}`} key={creator.id} className="group">
                                <div className="group">
                                    <div className="rounded-full">
                                        <img
                                            src={creator.profileInfo.profilePicUrl}
                                            className="rounded-full w-46 h-46 mx-auto"
                                            alt={`${creator.profileInfo.username}'s profile`}
                                        />
                                    </div>
                                    <h3 className="mt-4 text-xl font-bold text-gray-700" style={{ textAlign: "center" }}>
                                        @{creator.profileInfo.username}
                                    </h3>
                                    <div className="flex justify-center">
                                        <span style={{ marginTop: "5%" }} className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                            {creator.profileInfo.mainGame}
                                        </span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                    {loading && <p className="text-center text-gray-500 mt-5">Loading...</p>}
                </div>
            </div>
        </>
    );
}
