import axios from 'axios';

const useVideos = () => {
    

    const getVideosFromLocalStorage = () => {
        const storedVideos = localStorage.getItem('videos');
        return storedVideos ? JSON.parse(storedVideos) : [];
    };

    const setVideosInLocalStorage = (data) => {
        localStorage.setItem('videos', JSON.stringify(data));
        //save timestamp to local storage 
        localStorage.setItem('videos_timestamp', Date.now());
    };

    const isVideosStale = () => {
        //if 24 hours have passed since the last fetch
        const lastFetched = localStorage.getItem('videos_timestamp');
        return lastFetched ? Date.now() - lastFetched > 86400000 : true;
    }

    const fetchVideos = async (token, revalidate = false) => {

        if (!token || token === 'undefined' || token.length === 0) {
            throw new Error('No access token found, Please Connect your YouTube Account');
        }

        if (!revalidate) {
            const cachedVideos = getVideosFromLocalStorage();
            if (cachedVideos.length > 0) {
                if (!isVideosStale()) 
                    return cachedVideos;

            }
        }

        console.log('Fetching videos from API');
        try {
            const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
                params: {
                    part: 'snippet',
                    type: 'video',
                    forMine: true,
                    maxResults: 10,
                    order:'viewCount'
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const fetchedVideos = response.data.items;
            setVideosInLocalStorage(fetchedVideos);
            return fetchedVideos;
        } catch (error) {
            throw new Error(error.response.data.error.message);
        }
    };

    return { fetchVideos };
};

export default useVideos;