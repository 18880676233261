import React, { useState } from 'react';
import { getDatabase, ref, push } from 'firebase/database';
import { auth, db } from '../Context/firebaseConfig';
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { useAuth } from '../Context/AuthContext';
import { Dialog, Transition } from '@headlessui/react';

const OrderButton = ({ openPopup }) => {
  return (
    <button
      onClick={openPopup}
      className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
    >
      <span>Order</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"
        />
      </svg>
    </button>
  );
};

const ChatWindow = ({ messages, currentUser, userDetails, selectedChat }) => {
  const [newMessage, setNewMessage] = useState('');
  const [open, setOpen] = useState(true);
  const [orderTitle, setOrderTitle] = useState("");
  const [orderDescription, setOrderDescription] = useState("");
  const [orderPrice, setOrderPrice] = useState("");
  const [finalPrice, setFinalPrice] = useState("");
  const [status, setStatus] = useState("pending");
  // const [questionOne, setQuestionOne] = useState("");
  // const [questionTwo, setQuestionTwo] = useState("");
  // const [questionThree, setQuestionThree] = useState("");
  // const [questionFour, setQuestionFour] = useState("");
  const [daysNeeded, setDaysNeeded] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [isDaysModalOpen, setIsDaysModalOpen] = useState(false);
  const { activeUser } = useAuth();
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };


  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;

    const db = getDatabase();
    const chatRef = ref(db, `chatMessages/${selectedChat}/messages`);
    const messageData = {
      senderId: currentUser.uid,
      text: newMessage,
      timestamp: Date.now(),
      type: 'text',
    };

    push(chatRef, messageData);
    setNewMessage('');
  };

  const friendId = selectedChat?.split('_').find(id => id !== currentUser.uid);
  console.log("Friend ID:", friendId);
  const friendDetails = userDetails[friendId] || {};
  const currentUserDetails = userDetails[currentUser.uid] || {};
  const firestoreDB = collection(db, 'Users');

  function generateOrderId() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 13) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const calculateFees = () => {
    const cleanedString = orderPrice.replace(/[^0-9.-]+/g, '');
    const priceFloat = parseFloat(cleanedString);
    if (isNaN(priceFloat)) {
      console.error("Invalid price:", cleanedString);
    }
    const fees = (priceFloat * 0.15) + 0.30 + (priceFloat * 0.029);
    return parseFloat(fees.toFixed(2));
  };

  const orderNumber = generateOrderId();

  const currentDate = new Date(Date.now());

  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate();
  const year = currentDate.getFullYear();

  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  const formattedDate = `${month}/${day}/${year}`;

  function calculateDeliveryDate(daysNeeded) {
    const deliveryDate = new Date(currentDate);
    deliveryDate.setDate(deliveryDate.getDate() + daysNeeded);
    let month = deliveryDate.getMonth() + 1;
    let day = deliveryDate.getDate();
    const year = deliveryDate.getFullYear();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    const formattedDeliveryDate = `${month}/${day}/${year}`;
    return formattedDeliveryDate;
  }

  function checkInputStrLength(orderTitle, orderDescription, orderPrice, daysNeeded) {
    switch (true) {
      case orderTitle.trim().length < 10:  // Trigger the modal if the title is less than 10 characters
        setIsTitleModalOpen(true);
        return false;
      case orderDescription.trim().length < 100 || orderDescription.trim().length > 250:  // Trigger the modal if the description is not within the range
        setIsDescriptionModalOpen(true);
        return false;
      case parseFloat(orderPrice) < 10:  // Trigger the modal if the price is less than 10
        setIsPriceModalOpen(true);
        return false;
      case parseFloat(daysNeeded) >= 7:  // Trigger the modal if the days needed are more than 7
        setIsDaysModalOpen(true);
        return false;
      default:
        startOrder(orderTitle.trim(), orderDescription.trim(), orderPrice, daysNeeded);
        return true;
    }
  }



  const startOrder = async (orderTitle, orderDescription, orderPrice, daysNeeded) => {
    console.log("Starting Order...");

    const fees = calculateFees();
    const initialPrice = parseFloat(orderPrice);
    const finalPrice = initialPrice + fees;

    if (isNaN(initialPrice)) {
      console.error("Invalid order price:", orderPrice);
      return;
    }

    const orderData = {
      orders: {
        pending: {
          [orderNumber]: {
            title: orderTitle,
            description: orderDescription,
            price: {
              initial: initialPrice,
              fees: fees,
              final: finalPrice,
            },
            orderNumber: orderNumber,
            creatorId: friendId,
            editorId: currentUser.uid,
            status: status,
            timestamp: formattedDate,
            daysNeeded: daysNeeded,
            dueDate: calculateDeliveryDate(parseInt(daysNeeded)),
          },
        }
      }
    };

    console.log("Order data:", orderData);
    try {
      const orderMessage = {
        senderId: currentUser.uid,
        text: "A new order has been submitted, please view the order details - E2C.",
        timestamp: formattedDate,
        type: "order",
        orderReferenceNumber: orderNumber,
      };

      const orderLinkMessage = {
        senderId: currentUser.uid,
        text: `View Order Details`,
        timestamp: formattedDate,
        type: "orderLink",
        orderReferenceNumber: orderNumber,
      };

      const currentUserDocRef = doc(db, "Users", currentUser.uid);
      await setDoc(currentUserDocRef, orderData, { merge: true });

      console.log("Order data successfully set for current user.");

      const friendDocRef = doc(db, "Users", friendId);
      await setDoc(friendDocRef, orderData, { merge: true });

      sendOrderMessageData(orderMessage, orderLinkMessage);
      closePopup();
    } catch (error) {
      console.error("Error setting order data:", error);
    }
  };



  function sendOrderMessageData(message, linkMessage) {
    const db = getDatabase();
    const chatRef = ref(db, `chatMessages/${selectedChat}/messages`);
    push(chatRef, message);
    push(chatRef, linkMessage);
  }

  const renderMessage = (message, index) => {
    const isCurrentUser = message.senderId === currentUser.uid;
    const profilePicUrl = isCurrentUser
      ? currentUserDetails.profileInfo?.profilePicUrl
      : friendDetails.profileInfo?.profilePicUrl;

    if (message.type === 'order') {
      return (
        <div key={index} className={`col-start-${isCurrentUser ? '6' : '1'} col-end-${isCurrentUser ? '13' : '8'} p-3 rounded-lg`}>
          <div className={`flex items-center ${isCurrentUser ? 'justify-start flex-row-reverse' : ''}`}>
            {/* <img
              src={profilePicUrl || 'default-profile-pic-url'}
              alt="profile"
              className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0"
            /> */}
            <div className={`relative ml-3 text-sm bg-${isCurrentUser ? 'indigo-100' : 'white'} py-2 px-4 shadow rounded-xl`}>
              <div>{message.text}</div>
            </div>
          </div>
        </div>
      );
    }

    if (message.type === 'orderLink') {
      return (
        <div key={index} className={`col-start-${isCurrentUser ? '6' : '1'} col-end-${isCurrentUser ? '13' : '8'} p-3 rounded-lg`}>
          <div className={`flex items-center ${isCurrentUser ? 'justify-start flex-row-reverse' : ''}`}>
            {/* <img
              src={profilePicUrl || 'default-profile-pic-url'}
              alt="profile"
              className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0"
            /> */}
            <div className={`relative ml-3 text-sm bg-${isCurrentUser ? 'indigo-100' : 'white'} py-2 px-4 shadow rounded-xl`}>
              <button
                onClick={() => window.location.href = `/order/${message.orderReferenceNumber}`}
                className="text-blue-600 hover:text-blue-800 underline"
              >
                {message.text}
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div key={index} className={`col-start-${isCurrentUser ? '6' : '1'} col-end-${isCurrentUser ? '13' : '8'} p-3 rounded-lg`}>
        <div className={`flex items-center ${isCurrentUser ? 'justify-start flex-row-reverse' : ''}`}>
          {/* <img
            src={profilePicUrl || 'default-profile-pic-url'}
            alt="profile"
            className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0"
          /> */}
          <div className={`relative ml-3 text-sm bg-${isCurrentUser ? 'indigo-100' : 'white'} py-2 px-4 shadow rounded-xl`}>
            <div>{message.text}</div>
          </div>
        </div>
      </div>
    );
  };


  return (
    <>
      <Transition show={isTitleModalOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsTitleModalOpen(false)}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <svg
                        className="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Order Title Must Be Be Longer Than 10 Characters
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => setIsTitleModalOpen(false)}
                    >
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Description Modal */}
      <Transition show={isDescriptionModalOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsDescriptionModalOpen(false)}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <svg
                        className="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Order Description Too Short
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Order description should be at least 200 characters and maximum 250.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => setIsDescriptionModalOpen(false)}
                    >
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Price Modal */}
      <Transition show={isPriceModalOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsPriceModalOpen(false)}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <svg
                        className="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Order Price Too Low
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Order price should be at least 10 USD.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => setIsPriceModalOpen(false)}
                    >
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Days Needed Modal */}
      <Transition show={isDaysModalOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsDaysModalOpen(false)}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                      <svg
                        className="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Days Needed Too Long
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Days needed should be less than 7 days.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => setIsDaysModalOpen(false)}
                    >
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div className="flex flex-col flex-auto h-full p-6">
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4">
          <div className="flex flex-row items-center pb-4">
            <img
              src={friendDetails.profileInfo?.profilePicUrl || ""}
              alt="profile"
              className="h-10 w-10 rounded-full"
            />
            <div className="ml-3 text-sm font-semibold">
              @{friendDetails.profileInfo?.username}
            </div>
          </div>
          <div className="flex flex-col h-full overflow-x-auto mb-4">
            <div className="grid grid-cols-12 gap-y-2">
              {messages.map(renderMessage)}
            </div>
          </div>
          <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
            <div className="flex-grow ml-4">
              <div className="relative w-full">
                <input
                  type="text"
                  className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                  value={newMessage}
                  onChange={(e) => setNewMessage(DOMPurify.sanitize(e.target.value))}
                />

                {isPopupOpen && (
                  <div style={{ width: "90%" }} className="relative z-10">
                    <div
                      transition
                      className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                    />

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <div
                          transition
                          className="relative w-9/10 max-w-screen-lg p-4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              checkInputStrLength(orderTitle, orderDescription, orderPrice, daysNeeded);
                              // startOrder();
                            }}
                          >
                            <div className="space-y-12">
                              <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  Start Your Order!
                                </h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                  In order to get started, you have to fill out
                                  your order information.
                                </p>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                      Order Title
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="orderTitle"
                                        type="text"
                                        required
                                        value={orderTitle}
                                        onChange={(e) =>
                                          setOrderTitle(DOMPurify.sanitize(e.target.value))
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900 w-max">
                                      Days Needed to Finish the Project
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="daysNeeded"
                                        type="number"
                                        required
                                        value={daysNeeded}
                                        onChange={(e) =>
                                          setDaysNeeded(DOMPurify.sanitize(e.target.value))
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-span-full">
                                    <label
                                      htmlFor="about"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Order Description
                                    </label>
                                    <div className="mt-2">
                                      <textarea
                                        id="orderDescription"
                                        rows={3}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue={""}
                                        required
                                        value={orderDescription}
                                        onChange={(e) =>
                                          setOrderDescription(DOMPurify.sanitize(e.target.value))
                                        }
                                      />
                                    </div>
                                    <p className="mt-3 text-sm leading-6 text-gray-600">
                                      Explain what you will do for this order,
                                      go into detail.
                                    </p>
                                  </div>

                                  <div>
                                    <label
                                      htmlFor="price"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Price
                                    </label>
                                    <div className="relative mt-2 rounded-md shadow-sm">
                                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">

                                      </div>
                                      <input
                                        id="price"
                                        name="price"
                                        type="number"
                                        required
                                        placeholder="0.00"
                                        value={orderPrice}
                                        onChange={(e) =>
                                          setOrderPrice(DOMPurify.sanitize(e.target.value))
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                      <div className="absolute inset-y-0 right-0 flex items-center">
                                        <label
                                          htmlFor="currency"
                                          className="sr-only"
                                        >
                                          Currency
                                        </label>
                                        <select
                                          name="currency"
                                          className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                        >
                                          <option>USD</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">
                                  Perfect Your Delivery
                                </h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                  Choose 4 questions to ask your customer about
                                  their order.
                                </p>

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                      Question 1
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="questionOne"
                                        type="text"
                                        value={questionOne}
                                        onChange={(e) =>
                                          setQuestionOne()
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="last-name"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Question 2
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="questionTwo"
                                        type="text"
                                        value={questionTwo}
                                        onChange={(e) =>
                                          setQuestionTwo()
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                      Question 3
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="questionThree"
                                        type="text"
                                        value={questionThree}
                                        onChange={(e) =>
                                          setQuestionThree()
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>

                                  <div className="sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                      Question 4
                                    </label>
                                    <div className="mt-2">
                                      <input
                                        id="questionFour"
                                        type="text"
                                        value={questionFour}
                                        onChange={(e) =>
                                          setQuestionFour()
                                        }
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>

                            <div className="mt-6 flex items-center justify-end gap-x-6">
                              <button
                                type="button"
                                onClick={closePopup}
                                className="text-sm font-semibold leading-6 text-gray-900"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="rounded-md bg-indigo-600 text-indigo-500  px-3 py-2 text-sm font-semibold shadow-sm"
                              >
                                Begin Order
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row gap-x-2 items-center mx-2">
              {activeUser.profileInfo?.role?.toLowerCase() === "editor" && (
                <OrderButton openPopup={openPopup} />
              )}
              <button
                onClick={handleSendMessage}
                className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
              >
                <span>Send</span>
                <span className="ml-2">
                  <svg
                    className="w-4 h-4 transform rotate-45 -mt-px"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatWindow;