import React from 'react';

export default function Terms() {
    return (
        <div className="container mx-auto px-4 py-10">
            <h1 className="text-4xl font-bold text-center mb-6">Terms and Conditions</h1>

            <p>Welcome to Kre8 Content, LLC. ("Kre8", "we", "us", or "our"), a marketplace for creators. By using or accessing our website and services, you ("User", "you") agree to comply with and be bound by the following terms and conditions. If you do not agree with these Terms, you must not use the platform.</p>

            <h2 className="text-2xl font-semibold mt-6">1. Eligibility</h2>
            <p>You must be at least 18 years old and legally able to enter into a binding contract to use our services. Users between the ages of 13 and 18 may use the platform under the supervision of a parent or legal guardian who owns the account. Users under 13 years old are strictly prohibited from using Kre8.</p>

            <h2 className="text-2xl font-semibold mt-6">2. Account Creation</h2>
            <p>To access certain services, you must create an account. You will be required to provide accurate information, including your email address, username, role, phone number, main game, profile picture, and social media links. You are responsible for maintaining the confidentiality of your account details and for all activities conducted under your account.</p>

            <h2 className="text-2xl font-semibold mt-6">3. Payments and Refunds</h2>
            <p>Payments for services on Kre8 are facilitated via Stripe. One-time purchases are processed through the platform, and any refunds are handled directly through Stripe according to their policies. Kre8 does not handle or process payments directly.</p>

            <h2 className="text-2xl font-semibold mt-6">4. User Conduct</h2>
            <p>We strive to maintain a friendly, professional environment on Kre8. By using the platform, you agree to:</p>
            <ul className="list-disc ml-8 mt-2">
                <li>Only communicate personal information (e.g., phone numbers or emails) within the platform’s secure order page if necessary for completing services.</li>
                <li>Conduct yourself with respect and refrain from using rude, abusive, or discriminatory language.</li>
                <li>Not harass, spam, or engage in any behavior that violates our policies or disrupts the experience for other users.</li>
            </ul>
            <p>Any attempts to defame competing creators, circumvent platform rules, or violate our community standards may result in suspension or termination of your account.</p>

            <h2 className="text-2xl font-semibold mt-6">5. Prohibited Activities</h2>
            <p>The following activities are prohibited on Kre8:</p>
            <ul className="list-disc ml-8 mt-2">
                <li>Spamming or soliciting users to communicate or transact outside of Kre8.</li>
                <li>Submitting proposals or soliciting parties for contracts, engagements, or payments outside of Kre8.</li>
                <li>Engaging in fraud, unlawful activities, or any action that violates these Terms.</li>
                <li>Infringing on the intellectual property rights of others.</li>
                <li>Providing adult services or pornography.</li>
                <li>Misusing the platform’s communication channels for phishing or malicious activity.</li>
            </ul>
            <p>Violation of these terms may result in your account being permanently disabled.</p>

            <h2 className="text-2xl font-semibold mt-6">6. Intellectual Property and Content</h2>
            <p>By creating and offering services on Kre8, you represent that the content you provide is original and does not infringe upon the rights of any third party. In the event that any media (e.g., music or stock footage) is incorporated, you warrant that you have the necessary licenses for its use.</p>
            <p>Users may report violations of these terms through our reporting system. Kre8 reserves the right to take action against any user or content that violates these terms.</p>

            <h2 className="text-2xl font-semibold mt-6">7. Service Termination</h2>
            <p>Kre8 reserves the right to terminate or suspend accounts at any time for violations of these Terms, including, but not limited to, providing low-quality services, engaging in fraudulent activities, or violating our conduct policies. Users may also deactivate their accounts at any time, but remain responsible for any outstanding obligations.</p>

            <h2 className="text-2xl font-semibold mt-6">8. Limitation of Liability</h2>
            <p>Kre8 provides its platform “as is” and makes no warranties or guarantees about the level of service offered by creators. Kre8 is not liable for any interactions or transactions that occur outside the platform or any damages resulting from the use of the platform, including service interruptions or technical errors.</p>

            <h2 className="text-2xl font-semibold mt-6">9. Governing Law</h2>
            <p>These Terms are governed by the laws of the United States. Any disputes arising from these Terms or your use of the platform will be subject to the exclusive jurisdiction of the courts in the USA.</p>

            <h2 className="text-2xl font-semibold mt-6">10. Modifications</h2>
            <p>Kre8 reserves the right to modify or update these Terms at any time. Changes will be posted on the platform, and continued use of the platform after any modifications constitutes acceptance of the new Terms.</p>

            <h2 className="text-2xl font-semibold mt-6">11. Reporting Violations</h2>
            <p>If you come across any violations of these Terms or suspect misconduct, you can report it through our reporting system or contact our customer support team. Misuse of the reporting system may result in account suspension.</p>
        </div>
    );
}
