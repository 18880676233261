import React,{useEffect,useState} from 'react'
import { onMessage } from 'firebase/messaging';
import { auth, messaging } from '../../Context/firebaseConfig';
import {getUserDetails,clearNotifications} from '../../api/user';
import { toast } from 'react-toastify';
const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) return;
      setLoading(true);
      const userDoc = await getUserDetails(currentUser);
      if (userDoc) {
        setNotifications(userDoc.notifications || []);
        setLoading(false);
      }
    };

    fetchNotifications();

    const unsubscribe = onMessage(messaging, (payload) => {
      toast.info(payload.notification.body);
      //append the new notification to the list
      setNotifications((prev) => [...prev, payload.notification]);
      fetchNotifications();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleClear = async () => {
    try { 
      setNotifications([]);
      await clearNotifications();
      toast.success('Notifications cleared');
      
    } catch (error) {
      console.error('An error occurred while clearing notifications:', error);
      toast.error('An error occurred while clearing notifications');
    }
  }

  return (
    <div className="flex items-center justify-center">
          <img src="/icons/bell.svg" alt="Notifications" className="h-6 w-6 cursor-pointer" onClick={() => setShowDropDown(!showDropDown)} />

          <div className="relative">
            {showDropDown && (
              <div className="absolute right-0 top-6 mt-2 w-72 bg-white rounded-lg shadow-xl overflow-hidden z-10 max-h-[400px] overflow-y-auto">
                <div className="p-2 bg-gray-200 border-b">
                  <p className="text-sm text-gray-600">
                    Notifications { loading ? 'Loading...' : `(${notifications.length})`}
                  </p>
                </div>
                <div className="p-2">
                  {loading ? (
                    <p>Loading...</p>
                  ) : notifications.length === 0 ? (
                    <p>No new notifications</p>
                  ) : (
                    <>
                    {notifications.map((notification, index) => (
                      <div key={index} className="flex flex-col items-left p-2 hover:bg-gray-100 cursor-pointer" onClick={() => {
                        window.location.href = notification.navigationLink;
                        setShowDropDown(false);
                      }
                      }>
                        <p className="text-sm text-gray-600">{notification.title}</p>
                        <p className="text-xs text-gray-400">{notification.body}</p>
                      </div>
                    ))}
                    <div className="flex justify-center p-2 border-t">
                      <button onClick={handleClear} className="text-sm text-red-500">Clear Notifications</button>
                    </div>

                    </>

                    
                  )}
                </div>
              </div>
            )}
          </div>
    </div>
  );
}

export default Notifications

