import React from "react";
import TheNav from "../Components/navbar";
import EditorProfile from "../Components/editorProfileScreen";

const editorPortfolio = () => {
  return (
    <>
      <TheNav />
      <EditorProfile />
    </>
  );
};

export default editorPortfolio;
