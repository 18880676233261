import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
const OrderDelivery = ({ orderDetails }) => {
  const { files } = orderDetails;
  const [loading, setLoading] = useState("");
  const approveOrder = async () => {
    // Implement approve order logic here
    setLoading("approve");
    axios
      .post(`${process.env.REACT_APP_ENDPOINT}/order/approve`, {
        creatorId: orderDetails.creatorId,
        editorId: orderDetails.editorId,
        orderNumber: orderDetails.orderNumber,
      })
      .then((response) => {
        console.log("Order approved successfully", response);
        setLoading("");
      })
      .catch((error) => {
        console.error("Error approving order", error);
        setLoading("");
      });
  };

  const rejectOrder = async () => {
    // Implement reject order logic here
    setLoading("reject");
    axios
      .post(`${process.env.REACT_APP_ENDPOINT}/order/reject`, {
        creatorId: orderDetails.creatorId,
        editorId: orderDetails.editorId,
        orderNumber: orderDetails.orderNumber,
      })
      .then((response) => {
        console.log("Order rejected successfully", response);
        setLoading("");
      })
      .catch((error) => {
        console.error("Error rejecting order", error);
        setLoading("");
      });
  };
  return (
    <div className="flex flex-col w-full text-center mt-5 gap-y-5">
      {files?.length > 0 ? (
        <>
          <div className="flex flex-col w-full text-left">
            <div className="flex justify-start flex-row flex-wrap gap-x-4">
              {files?.map((file) => (
                <div
                  key={file.id}
                  className="border-2 p-2 my-2 rounded-md cursor-pointer"
                  onClick={() => window.open(file.publicUrl, "_blank")}
                >
                  <p className="text-sm">{file.name}</p>
                  <p className="text-xs">
                    {(file.size / 1000000).toFixed(2)} MB
                  </p>
                  <p className="text-xs">{file.type}</p>
                </div>
              ))}
            </div>
          </div>

          {orderDetails?.status === "approved" && (
            <p className="text-base text-left">Order has been approved</p>
          )}

          <div className="flex justify-start gap-x-4">
            <button
              className="bg-[#1f2937] text-white p-2 rounded mt-2 w-max "
              onClick={approveOrder}
              disabled={
                loading === "approve" || orderDetails?.status === "approved"
              }
            >
              Approve Order
            </button>
            <button
              className="bg-red-500 text-white p-2 rounded mt-2 w-max "
              onClick={rejectOrder}
              disabled={
                loading === "reject" || orderDetails?.status === "approved"
              }
            >
              Reject Order
            </button>
          </div>
        </>
      ) : (
        <p className="text-lg text-left">No files uploaded yet</p>
      )}
    </div>
  );
};

export default OrderDelivery;
