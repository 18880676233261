import React from 'react';
import { BanknotesIcon, SparklesIcon, FingerPrintIcon, CreditCardIcon, ChatBubbleLeftRightIcon, Cog8ToothIcon, ClipboardDocumentListIcon, CurrencyDollarIcon, UserGroupIcon } from '@heroicons/react/20/solid'
import "../CSS/output.css";
import TheNav from '../Components/navbar';
import Jason from '../images/jason.png';
import Shot1 from '../images/ss1.png';
import Footer from '../Components/footer';
import { Link } from 'react-router-dom'

const Main = () => {
  const features = [
    {
      name: 'Editors KEEP 100%.',
      description:
        'Other platforms take a cut of YOUR hard earned work along with tips, you keep 100%.',
      icon: BanknotesIcon,
    },
    {
      name: 'BE YOU.',
      description: "Show off exactly who you are, within reason of course. It's important for creators and editors to be on the same page.",
      icon: SparklesIcon,
    },
    {
      name: 'Verify your work.',
      description: "Log into your YouTube account as a creator and editors link videos they've worked on. Trust is important, be who you say you are.",
      icon: FingerPrintIcon,
    },
  ]

  const featuresTwo = [
    {
      name: 'Save From Fiverr.',
      description:
        'Save 7% or more from Fiverr, Upwork and oher companies with bs fees.',
      icon: CreditCardIcon,
    },
    {
      name: 'Build a Connection.',
      description: "When becoming an editor/creator duo, your content GREATLY improves when you work well togther.",
      icon: ChatBubbleLeftRightIcon,
    },
    {
      name: 'In Depth Profize Customization.',
      description: "Customize your profile to find better matches.",
      icon: Cog8ToothIcon,
    },
    {
      name: 'Track EVERYTHING .',
      description:
        'Track all your orders, money, spent and earned.',
      icon: ClipboardDocumentListIcon,
    },
    {
      name: 'REWARDS REWARDS REWARDS.',
      description: "You're a valued customer, so take part in our rewards program.",
      icon: CurrencyDollarIcon,
    },
    {
      name: 'FAST Customer Support.',
      description: "Reach out and get support within 10 hours for any issue.",
      icon: UserGroupIcon,
    },
  ]

  return (
    <>
      <TheNav />
      <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
              Content Creation <br />
              without the bs.
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              For content creators and editors, by content creators and editors.
            </p>
          </div>
          <div className="lg:mt-0 lg:col-span-5 flex justify-center w-7/12">
            <img
              src={Jason}
              alt="mockup"
              className="w-full h-auto"
            />
          </div>

        </div>
      </section>

      <section>
        <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-full grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-full lg:grid-cols-2 items-start">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg text-left">
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">GROW With Us</h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A Better Way</p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    As a team of content creators and editors, we know EXACTLY how hard it is to find the right people to help you grow.
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-600" />
                          {feature.name}
                        </dt>{' '}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="flex justify-center lg:justify-end">
                <img
                  alt="Product screenshot"
                  src={Shot1}
                  className="w-full h-auto rounded-xl shadow-xl ring-1 ring-gray-400/10"
                />
              </div>
            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Join a Community</h2>
              <Link to="/signup">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Get Started
                </p>
                <span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10" style={{ marginTop: "4%" }}>Grow Your Presence!</span>
              </Link>
            </div>
            <div className="mx-auto mt-16 max-w-6xl sm:mt-20 lg:mt-24 lg:max-w-full">
              <dl className="grid max-w-full grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 lg:gap-y-16">
                {featuresTwo.map((feature) => (
                  <div key={feature.name} className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                        <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                      </div>
                      {feature.name}
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>


          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Main;
