import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from "../../Context/AuthContext";

const OverlayLoader = () => (
  <div className="fixed top-0 left-0 z-50 w-full h-full bg-gray-600 border-indigo-600 bg-opacity-80 flex items-center justify-center">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-600"></div>
    <p className="text-white text-2xl font-semibold ml-4">Processing Request</p>
  </div>
);

const OrderRow = React.memo(({ order, onClick, loading, handleWithdraw }) => (
  <tr className="border-b hover:bg-gray-50">
    <td className="px-4 py-2 cursor-pointer" onClick={onClick}>
      {order.orderNumber}
    </td>
    <td className="px-4 py-2">{order.title}</td>
    <td className="px-4 py-2">{order.description}</td>
    <td className="px-4 py-2">{order.dueDate}</td>
    <td className="px-4 py-2">{order.daysNeeded}</td>
    <td className="px-4 py-2">${order.price?.initial?.toFixed(2)}</td>
    <td className="px-4 py-2">
      <span
        className={`px-2 py-1 rounded-md text-xs font-semibold ${order.status === "pending"
            ? "bg-yellow-200 text-yellow-800"
            : order.status === "completed"
              ? "bg-green-200 text-green-800"
              : "bg-gray-200 text-gray-800"
          }`}
      >
        {order.status?.toUpperCase()}
      </span>
    </td>
    <td className="px-4 py-2 text-xs">
      {order.status === "approved" ? (
        <button
          className="px-2 py-1 bg-green-500 text-white rounded-md uppercase"
          onClick={handleWithdraw}
          disabled={loading || order?.earningsStatus === "paid"}
        >
          {order?.earningsStatus === "paid" ? "Withdrawn" : "Withdraw"}
        </button>
      ) : (
        <button className="px-2 py-1 bg-gray-200 text-gray-800 rounded-md uppercase" disabled>
          Pending
        </button>
      )}
    </td>
  </tr>
));

const EditorOrders = ({ orders, user, setOrders }) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const flattenedOrders = useMemo(() => {
    if (!orders || Object.keys(orders).length === 0) return [];
    return Object.values(orders)
      .flatMap((statusOrders) => (statusOrders ? Object.values(statusOrders) : []))
      .filter((order) => order !== undefined);
  }, [orders]);

  const requestWithdraw = (order) => {
    if (!user?.connectedAccountId) {
      toast.error("Connected account not found");
      return;
    }
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_ENDPOINT}/withdraw-earnings`, {
        uid: currentUser.uid,
        orderId: order.orderNumber,
        amount: order.price.initial,
        connectedAccountId: user.connectedAccountId,
      })
      .then(() => {
        toast.success("Earnings Withdrawn Successfully");
        setOrders((prevOrders) => ({
          ...prevOrders,
          current: {
            ...prevOrders.current,
            [order.orderNumber]: {
              ...order,
              earningsStatus: "paid",
            },
          },
        }));
      })
      .catch((error) => {
        toast.error(error.response?.data || "Error occurred");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col w-full text-center mt-5 gap-y-5">
      {loading && <OverlayLoader />}
      <h1 className="text-2xl font-bold mb-4 text-left">Editor's Order List</h1>
      <div className="overflow-x-auto">
        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2">Order Number</th>
              <th className="px-4 py-2">Title</th>
              <th className="px-4 py-2">Description</th>
              <th className="px-4 py-2">Due Date</th>
              <th className="px-4 py-2">Days Needed</th>
              <th className="px-4 py-2">Price</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Earnings</th>
            </tr>
          </thead>
          <tbody>
            {flattenedOrders.length > 0 ? (
              flattenedOrders.map((order) => (
                <OrderRow
                  key={order.orderNumber}
                  order={order}
                  onClick={() => navigate(`/order/${order.orderNumber}`)}
                  handleWithdraw={() => requestWithdraw(order)}
                  loading={loading}
                />
              ))
            ) : (
              <tr>
                <td colSpan="8" className="px-4 py-2 text-center text-gray-500">
                  No orders available.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="flex justify-between mt-4">
          <div>
            <h2 className="text-lg font-bold">Active Orders</h2>
            <p className="text-lg font-semibold">
              ${flattenedOrders
                .filter((order) => order.status === "current" || order.status === "revision")
                .reduce((acc, order) => acc + (order.price?.initial || 0), 0)
                .toFixed(2)}
            </p>
          </div>
          <div>
            <h2 className="text-lg font-bold">Total Completed Orders</h2>
            <p className="text-lg font-semibold">
              ${flattenedOrders
                .filter((order) => order.status === "approved")
                .reduce((acc, order) => acc + (order.price?.initial || 0), 0)
                .toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EditorOrders);
