import React from 'react'

const VideoCard = ({ video }) => (
  <div className="border border-gray-200 rounded-lg shadow-lg p-4 flex flex-col items-center bg-white hover:shadow-xl transition-shadow duration-300">
    <h3 className="text-xl font-semibold mb-2 text-gray-800">
      {video?.snippet?.title}
    </h3>
    <img
      src={video?.snippet?.thumbnails.default.url}
      alt={video?.snippet?.title}
      className="mb-4 rounded w-full object-cover"
    />
    <p className="text-gray-600 text-sm">{video?.statistics?.viewCount}</p>
  </div>
);

export default VideoCard