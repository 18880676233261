import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore"; // Import Firestore functions
import { useAuth } from "../Context/AuthContext"; // Import your Auth context
import { db } from "../Context/firebaseConfig"; // Import your Firestore config
import Ronaldo from "../images/Stable.jpg";
import Logo from "../images/logo.png";

export default function CreateNewCategory() {
    const [formData, setFormData] = useState({
        categoryName: "",
        categoryImage: "",
    });

    const { currentUser } = useAuth(); // Assuming you have a context for auth

    const handleChange = (e) => {
        // const { name, value } = e.target;
        // setFormData(prevFormData => ({
        //     ...prevFormData,
        //     [name]: value,
        // }));
        const sanitizedValue = DOMPurify.sanitize(value);

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: sanitizedValue,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { categoryName, categoryImage } = formData;
        try {
            if (currentUser) { // Check if the user is authenticated
                const categoryData = {
                    categoryName: categoryName,
                    categoryImage: categoryImage,
                };

                await addDoc(collection(db, 'Categories'), categoryData);

                alert(`Category added successfully: ${categoryName}`);
            } else {
                alert("You must be logged in to add a category.");
            }
        } catch (error) {
            if (error instanceof Error) {
                alert(`Error adding category: ${error.message}`);
            } else {
                alert("An unexpected error occurred.");
            }
        }
    };

    return (
        <div
            className="flex min-h-screen bg-gray-100"
            style={{
                backgroundImage: `url(${Ronaldo})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-lg lg:w-96 bg-white p-8 rounded-lg shadow-lg">
                    <div>
                        <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 text-center">
                            Add a New Category
                        </h2>
                        <img
                            alt="Logo"
                            src={Logo}
                            className="mx-auto lg:w-60  p-8"
                        />
                    </div>

                    <div className="mt-8">
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <label htmlFor="categoryName" className="block text-sm font-medium text-gray-700">
                                    Category Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="categoryName"
                                        name="categoryName"
                                        type="text"
                                        value={formData.categoryName}
                                        onChange={handleChange}
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="categoryImage" className="block text-sm font-medium text-gray-700">
                                    Category Image Link
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="categoryImage"
                                        name="categoryImage"
                                        type="text"
                                        value={formData.categoryImage}
                                        onChange={handleChange}
                                        required
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
