import React from 'react'
import VideoCard from './VideoCard'
import { Link } from 'react-router-dom'
const ChannelVideos = ({ videos }) => {
  return (
    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4">
      {videos.map((video) => (
        <Link key={video.id.videoId} to={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank" rel="noopener noreferrer">
        <VideoCard video={video} />
        </Link>
      ))}
    </div>
  );
};

export default ChannelVideos