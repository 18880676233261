import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, setDoc, doc, query, where } from "firebase/firestore";
import { auth, db } from '../Context/firebaseConfig';
import Edit from "../images/edit.jpg";
import Logo from "../images/logo.png";
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import UnusableWords from "../Components/unusableWords";
import DOMPurify from "dompurify";
import { set } from "firebase/database";

const Signup = () => {

    const [formData, setFormData] = useState({
        email: "",
        username: "",
        password: "",
        role: "",
        id: ""
    });

    const [isPasswordValid, setPasswordValid] = useState(true);
    const [isUsernameInappropriate, setUsernameInappropriate] = useState(false);
    const [isUsernameTaken, setUsernameTaken] = useState(false);
    const [isUsernameInvalid, setUsernameInvalid] = useState(false);
    const [categories, setCategories] = useState([]);
    const [isEmailValid, setEmailValid] = useState(true);
    const [isFormValid, setIsFormValid] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [joinEmailList, setJoinEmailList] = useState(false);
    const [formError, setFormError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate("/");
            }
        });

        const fetchCategories = async () => {
            try {
                const categoriesCollection = collection(db, 'Categories');
                const categoriesSnapshot = await getDocs(categoriesCollection);

                if (!categoriesSnapshot.empty) {
                    const categoriesList = categoriesSnapshot.docs.map(doc => ({
                        id: doc.id,
                        name: doc.data().categoryName,
                    }));

                    setCategories(categoriesList);
                    setSelectedCategory(categoriesList[0]);
                } else {
                    console.warn("No categories found in Firestore.");
                }
            } catch (error) {
                console.error("Error fetching categories: ", error);
            }
        };

        fetchCategories();

        return () => unsubscribe();
    }, [navigate]);

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (name === "agreeToTerms") {
            setAgreeToTerms(checked);
        } else if (name === "joinEmailList") {
            setJoinEmailList(checked);
        }
    };

    const handleRoleChange = (role) => {
        setFormData({ ...formData, role });
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        return regex.test(password);
    };

    const validateUsername = async (username) => {
        if (!username) {
            setUsernameInvalid(false);
            setUsernameInappropriate(false);
            setUsernameTaken(false);
            return;
        }

        const validUsername = /^[a-zA-Z0-9_]{3,20}$/.test(username);
        setUsernameInvalid(!validUsername);

        if (validUsername) {
            const inappropriate = UnusableWords.some(word => username.toLowerCase().includes(word.toLowerCase()));
            setUsernameInappropriate(inappropriate);

            if (!inappropriate) {
                const q = query(collection(db, "Users"), where("profileInfo.username", "==", username));
                const querySnapshot = await getDocs(q);
                setUsernameTaken(!querySnapshot.empty);
            }
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        const sanitizedValue = DOMPurify.sanitize(value);

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: sanitizedValue,
        }));

        if (name === "email") {
            setEmailValid(validateEmail(value));
        } else if (name === "password") {
            setPasswordValid(validatePassword(value));
        } else if (name === "username") {
            await validateUsername(value);
        }

        validateForm();
    };

    const validateForm = () => {
        setIsFormValid(isEmailValid && isPasswordValid && !isUsernameInvalid && !isUsernameInappropriate && !isUsernameTaken);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!agreeToTerms) {
            setFormError('You must agree to the terms and conditions to sign up.');
            return;
        }

        setFormError(null);
        if (!isFormValid) return;
        const { email, password, username, role, id } = formData;

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            if (userCredential.user && setJoinEmailList) {
                const user = userCredential.user;
                await setDoc(doc(db, 'Users', user.uid), {
                    profileInfo: {
                        email: user.email,
                        username: username,
                        role: role,
                        phoneNumber: "",
                        mainGame: selectedCategory.name,
                        id: user.uid,
                        about: "",
                        profilePicUrl: "https://static.vecteezy.com/system/resources/previews/036/280/650/original/default-avatar-profile-icon-social-media-user-image-gray-avatar-icon-blank-profile-silhouette-illustration-vector.jpg",
                    },
                    security: {
                        questions: {

                        }
                    },
                    socials: {
                        tiktok: "",
                        twitter: "",
                        twitch: "",
                        instagram: "",
                        youtube: ""
                    },
                    orders: {
                        previous: {},
                        pending: {},
                        current: {}
                    }
                });
                await setDoc(doc(db, 'EmailList'), {
                    email: user.email,
                    id: user.uid
                });


            } else if (userCredential.user && !setJoinEmailList) {
                const user = userCredential.user;
                await setDoc(doc(db, 'Users', user.uid), {
                    profileInfo: {
                        email: user.email,
                        username: username,
                        role: role,
                        phoneNumber: "",
                        mainGame: selectedCategory.name,
                        id: user.uid,
                        about: "",
                        profilePicUrl: "https://static.vecteezy.com/system/resources/previews/036/280/650/original/default-avatar-profile-icon-social-media-user-image-gray-avatar-icon-blank-profile-silhouette-illustration-vector.jpg",
                    },
                    security: {
                        questions: {

                        }
                    },
                    socials: {
                        tiktok: "",
                        twitter: "",
                        twitch: "",
                        instagram: "",
                        youtube: ""
                    },
                    orders: {
                        previous: {},
                        pending: {},
                        current: {}
                    }
                });
            }
        } catch (error) {
            if (error instanceof Error) {
                alert(`Error signing up: ${error.message}`);
            } else {
                alert("An unexpected error occurred.");
            }
        }
    };

    return (
        <>
            <header>
                <a href="https://discord.gg/GsTCqZkVjg">
                    <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
                        Join our Discord to stay up to date and get access to exclusive discount codes! Click here to join.
                    </p>
                </a>
            </header>
            <div
                className="flex min-h-screen bg-gray-100"
                style={{
                    backgroundImage: `url(${Edit})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-lg lg:w-96 bg-white p-8 rounded-lg shadow-lg">
                        <div>
                            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 text-center">
                                Create Videos at a Bigger Scale
                            </h2>
                            <img
                                alt=""
                                src={Logo}
                                className="mx-auto lg:w-60  p-8"
                            />
                            <p className="mt-2 text-sm text-gray-600 text-center">
                                Already a member?{' '}
                                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                    <Link to="/login"> Login here </Link>
                                </a>
                            </p>
                        </div>

                        <div className="mt-8">
                            <div className="mt-6">
                                <form onSubmit={handleSubmit} className="space-y-6">

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            Username
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="username"
                                                name="username"
                                                type="text"
                                                value={formData.username}
                                                onChange={handleChange}
                                                required
                                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                            {isUsernameInvalid && (
                                                <p className="text-red-500 text-xs mt-1">
                                                    Username is invalid. Please use 3-20 characters with only letters, numbers, or underscores.
                                                </p>
                                            )}
                                            {isUsernameInappropriate && (
                                                <p className="text-red-500 text-xs mt-1">
                                                    Username contains inappropriate words. Please choose a different one.
                                                </p>
                                            )}
                                            {isUsernameTaken && (
                                                <p className="text-red-500 text-xs mt-1">
                                                    Username is already taken. Please choose a different one.
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            Email address
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                autoComplete="email"
                                                required
                                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                            {!isEmailValid && (
                                                <p className="text-red-500 text-xs mt-1">
                                                    Email is invalid
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            Password
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                value={formData.password}
                                                onChange={handleChange}
                                                autoComplete="current-password"
                                                required
                                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                            <p className={`${isPasswordValid ? 'text-black-500' : 'text-black-500'} text-xs mt-1`}>
                                                Password must contain 1 uppercase, 1 lowercase, 1 digit and at least 8 characters
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">Select Your Main Game</label>
                                        <div className="relative mt-2">
                                            <Listbox value={selectedCategory} onChange={setSelectedCategory}>
                                                <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                                    <span className="block truncate">
                                                        {selectedCategory ? selectedCategory.name : 'Loading...'}
                                                    </span>
                                                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                        <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                                                    </span>
                                                </ListboxButton>
                                                <ListboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {categories.map((category) => (
                                                        <ListboxOption
                                                            key={category.id}
                                                            value={category}
                                                            className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                                        >
                                                            <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                                                {category.name}
                                                            </span>
                                                            {selectedCategory && selectedCategory.id === category.id ? (
                                                                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white">
                                                                    <CheckIcon aria-hidden="true" className="h-5 w-5" />
                                                                </span>
                                                            ) : null}
                                                        </ListboxOption>
                                                    ))}
                                                </ListboxOptions>
                                            </Listbox>
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <div className="inline-flex rounded-md shadow-sm" role="group">
                                            <button type="button" onClick={() => handleRoleChange('Creator')} className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                                                <svg className="w-3 h-3 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                                                </svg>
                                                Creator
                                            </button>

                                            <button type="button" onClick={() => handleRoleChange('Editor')} className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 border border-gray-900 rounded-r-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                                                <svg className="w-3 h-3 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                                                    <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                                                </svg>
                                                Editor
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                name="agreeToTerms"
                                                className="form-checkbox h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                                checked={agreeToTerms}
                                                onChange={handleCheckboxChange} />
                                            <span className="ml-2 text-gray-700">
                                                I agree to the
                                                <a href="/legal/terms" className="text-indigo-600 underline hover:text-indigo-500"> Terms and Conditions, </a>
                                                <a href="/legal/privacy" className="text-indigo-600 underline hover:text-indigo-500"> Privacy Policy, </a>
                                                <a href="/legal/cookies" className="text-indigo-600 underline hover:text-indigo-500"> Cookie Policy</a>
                                            </span>
                                        </label>
                                        {formError && (
                                            <p className="text-red-600 mt-2 text-sm">{formError}</p>
                                        )}
                                    </div>

                                    <div className="mt-4">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                name="joinEmailList"
                                                className="form-checkbox h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                                checked={joinEmailList}
                                                onChange={handleCheckboxChange} />
                                            <span className="ml-2 text-gray-700">
                                                Join our email list for updates and special offers
                                            </span>
                                        </label>
                                    </div>


                                    <div className="flex items-center justify-between" style={{ textAlign: "center" }}>
                                        <div className="text-sm">
                                            <h2 href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                <Link to="/reset"> Forgot password? </Link>
                                            </h2>
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            // disabled={isPasswordValid} disbaled currently
                                            className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            style={{ backgroundColor: 'indigo' }}
                                        >
                                            Sign Up 

                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        alt=""
                    />
                </div>
            </div>
        </>
    );
}

export default Signup;