import React from "react";

const UnusableWords = [
    "nigger","n!663r","monkey","chink","ch1nk","chink","ape","m0nk3y","monk3y",
    "nazi","naz1","adolf","ad0lf","ad01f","chingchong","chinaman","ch1ngch0ng","ch1naman",
    "bimbo","b1mb0","b3an3r","beaner","bean3r","b3aner","blackie","b1ack13","blacki3",
    "coño","cono","coñ0","c0ño","c0ñ0","coon","c00n","ass","a$s","a$$","sh!t","shit","fuck",
    "cottonpicker","c0tt0np1ck3r","darkie","dark1e","dark13","dei","gook","g00k",
    "jiggaboo","jigaboo","jigger","jigg","junglebunny","porchmonkey","mooncricket",
    "cunt","retard","kys","kill","niglet","nignog","negro","nigar",
];

export default UnusableWords;