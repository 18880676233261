import { db, auth } from "../Context/firebaseConfig";
import { doc, getDoc,updateDoc } from "firebase/firestore";


export const getUserToken = async (currentUser) => {
    if (currentUser) {
        const userDoc = await getDoc(doc(db, "Users", currentUser.uid));
        if (userDoc.exists() && userDoc.data().youtubeAccessToken) {
        return userDoc.data().youtubeAccessToken;
        }
    }
    return null;
    }

export const getUserDetails = async (currentUser) => {
    if (currentUser) {
        const userDoc = await getDoc(doc(db, "Users", currentUser.uid));
        if (userDoc.exists()) {
        return userDoc.data();
        }
    }
    return null;
    }

export const setUserCMFToken = async (token) => {
    try {
        const currentUser = auth.currentUser;
        if (currentUser) {
            const userDoc = await getDoc(doc(db, "Users", currentUser.uid));
            if (userDoc.exists()) {
                const userRef = doc(db, "Users", currentUser.uid);
                await updateDoc(userRef, {
                    cmfToken: token,
                });
            }
        }
    } catch (error) {
        console.error("An error occurred while updating CMF Token:", error);
        
    }
   
    }

export const addNotification = async (type,message) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
        return;
    }
    let notification = {
        type: type,
        message: message,
        status: "unread",
        timestamp: new Date().getTime(),
        n_id: Math.random().toString(36).substring(7),
    };
    //add status as unread
    try {
        const userDoc = await getDoc(doc(db, "Users", currentUser.uid));
        if (userDoc.exists()) {
            const userRef = doc(db, "Users", currentUser.uid);
            await updateDoc(userRef, {
                notifications: [...userDoc.data().notifications, notification],
            });
        }
    } catch (error) {
        console.error("An error occurred while adding notification:", error);
    }
    }

export const markNotificationAsRead = async (n_id) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
        return;
    }
    try {
        const userDoc = await getDoc(doc(db, "Users", currentUser.uid));
        if (userDoc.exists()) {
            const userRef = doc(db, "Users", currentUser.uid);
            const notifications = userDoc.data().notifications.map((notification) => {
                if (notification.n_id === n_id) {
                    return { ...notification, status: "read" };
                }
                return notification;
            });
            await updateDoc(userRef, {
                notifications: notifications,
            });
        }
    } catch (error) {
        console.error("An error occurred while marking notification as read:", error);
    }
    }


export const clearNotifications = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
        return;
    }
    try {
        const userDoc = await getDoc(doc(db, "Users", currentUser.uid));
        if (userDoc.exists()) {
            const userRef = doc(db, "Users", currentUser.uid);
            await updateDoc(userRef, {
                notifications: [],
            });
        }
    } catch (error) {
        console.error("An error occurred while clearing notifications:", error);
    }
    }