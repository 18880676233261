import React from 'react';

export default function Privacy() {
    return (
        <div className="container mx-auto px-4 py-10">
            <h1 className="text-4xl font-bold text-center mb-6">Privacy Policy</h1>

            <p>This Privacy Policy explains how Kre8 Content, LLC. ("Kre8", "we", "us", or "our") collects, uses, and protects the personal information you ("User", "you") provide when using our platform and services. By accessing or using Kre8, you consent to the practices described in this policy.</p>

            <h2 className="text-2xl font-semibold mt-6">1. Information We Collect</h2>
            <p>We collect the following types of personal information when you use our services:</p>
            <ul className="list-disc ml-8 mt-2">
                <li>Email address</li>
                <li>Username</li>
                <li>Phone number</li>
                <li>User ID</li>
                <li>About (biographical information)</li>
                <li>Profile picture</li>
                <li>Payment information (via Stripe)</li>
                <li>Social media links</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6">2. How We Collect Information</h2>
            <p>We collect personal information through the following methods:</p>
            <ul className="list-disc ml-8 mt-2">
                <li>Forms you fill out during account creation or updates</li>
                <li>Third-party services, including payment processing via Stripe</li>
                <li>Analytics tools to track and understand user behavior</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6">3. How We Use Your Information</h2>
            <p>We use the personal information we collect for the following purposes:</p>
            <ul className="list-disc ml-8 mt-2">
                <li>Account creation and management</li>
                <li>Improving the services we offer</li>
                <li>Allowing you to view and interact with other user profiles</li>
                <li>Processing payments and creating orders</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6">4. Sharing Your Information</h2>
            <p>We share your personal information with third-party services only to the extent necessary for them to provide their services, such as payment processing (Stripe) and analytics tools. We do not sell or share your personal information with third parties for marketing purposes.</p>

            <h2 className="text-2xl font-semibold mt-6">5. Data Retention</h2>
            <p>We retain your personal data as long as your account is active. If you choose to delete your account, we will remove your personal data from our system upon request, except for information that must be retained for legal or compliance purposes.</p>

            <h2 className="text-2xl font-semibold mt-6">6. Accessing and Deleting Your Data</h2>
            <p>You can access, update, or delete your personal data through your account settings. If you have any issues managing your personal data, please contact our support team for assistance.</p>

            <h2 className="text-2xl font-semibold mt-6">7. Cookies and Tracking Technologies</h2>
            <p>We use cookies and other tracking technologies for authentication and analytics purposes. These technologies help us improve user experience and monitor performance on the platform.</p>

            <h2 className="text-2xl font-semibold mt-6">8. Security Measures</h2>
            <p>We take the protection of your personal data seriously and implement the following security measures:</p>
            <ul className="list-disc ml-8 mt-2">
                <li>Data encryption</li>
                <li>Cloudflare protection</li>
                <li>XSS (cross-site scripting) prevention</li>
                <li>Secure passwords and authentication</li>
                <li>SSL and HTTPS for secure transmission</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6">9. Governing Law</h2>
            <p>This Privacy Policy is governed by the laws of the United States. By using our services, you agree that any disputes regarding your personal data will be subject to the jurisdiction of the courts in the USA.</p>

            <h2 className="text-2xl font-semibold mt-6">10. Children’s Privacy</h2>
            <p>Users must be at least 18 years old to use our services. Users between the ages of 13 and 18 may use the platform under the supervision of a parent or legal guardian who owns the account. Users under 13 are not permitted to use Kre8.</p>

            <h2 className="text-2xl font-semibold mt-6">11. Changes to This Privacy Policy</h2>
            <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page, and by continuing to use the platform, you accept any changes to the policy.</p>

            <p className="mt-6">If you have any questions about this Privacy Policy or how we handle your data, please contact us at support@kre8.com.</p>
        </div>
    );
}
