import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc,collection,query,where,getDocs,setDoc } from 'firebase/firestore';
import { auth, db } from '../Context/firebaseConfig';
import { FaInstagram, FaYoutube, FaTwitch, FaTwitter, FaTiktok } from 'react-icons/fa';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { useAuth } from '../Context/AuthContext';
import { toast } from 'react-toastify';
import { ref, getDatabase, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
export default function CreatorProfile({ creatorName }) {
    const [editor, setEditor] = useState(null);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const { currentUser } = useAuth();
    const [userId, setUserId] = useState(null);
    const [videoLinks, setVideoLinks] = useState({
        videoOne: '',
        videoTwo: '',
        videoThree: '',
        videoFour: '',
        videoFive: '',
        videoSix: '',
        videoSeven: '',
        videoEight: '',
        videoNine: '',
        videoTen: '',
    });
    const [videos, setVideos] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCreator = async () => {
            try {
                const usersRef = collection(db, 'Users');
                const q = query(usersRef, where('profileInfo.username', '==', creatorName));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const userData = querySnapshot.docs[0].data();
                    setUserId(querySnapshot.docs[0].id);
                    setEditor({ id: querySnapshot.docs[0].id, ...userData });
                    
                    const videoLinks = userData.videoLinks;

                    if (videoLinks) {
                        fetchVideoData(Object.values(videoLinks));
                    }
                } else {
                    setError("No editor found with that ID");
                }
            } catch (err) {
                setError("Failed to fetch data or insufficient permissions");
                console.error("Error fetching data or permissions issue:", err);
            }
        };

        const fetchVideoData = async (urls) => {
            try {
                const videoData = await Promise.all(
                    urls.map(async (url) => {
                        const videoId = extractYouTubeId(url);
                        if (!videoId) return null;

                        const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}&part=snippet,contentDetails,statistics`);
                        const data = await response.json();

                        console.log(`Response for video ID ${videoId}:`, data); // Log the entire response

                        if (data.items && data.items.length > 0) {
                            return data.items[0];
                        } else {
                            console.warn(`No video found for ID: ${videoId}`);
                            return null;
                        }
                    })
                );

                setVideos(videoData.filter(video => video !== null));
            } catch (error) {
                console.error("Error fetching video data:", error);
            }
        };

        const extractYouTubeId = (url) => {
            const regExp = /^https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})(?=$|[&])/;
            const match = url.match(regExp);
            return match ? match[1] : null;
        };


        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                fetchCreator(user);
            } else {
                setError("No user is logged in");
            }
        });

        return () => unsubscribe();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setVideoLinks((prevLinks) => ({
            ...prevLinks,
            [name]: value,
        }));
    };

    const handleSaveVideos = async () => {
        try {
            const currentUser = auth.currentUser;
            const docRef = doc(db, "Users", currentUser.uid);

            await updateDoc(docRef, {
                videoLinks: { ...videoLinks }
            });

            setModalOpen(false);
        } catch (error) {
            console.error("Error updating video links:", error);
            setError("Failed to update video links");
        }
    };

    const convertDuration = (duration) => {
        const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
        const hours = parseInt(match[1]) || 0;
        const minutes = parseInt(match[2]) || 0;
        const seconds = parseInt(match[3]) || 0;
        return `${hours > 0 ? `${hours}:` : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!editor) {
        return <p>Loading...</p>;
    }

    const createChat = async () => {
        if (!currentUser) {
            toast.error("You must be logged in to send a message");
            return;
        }

        try {
            // Fetch current user info from Firestore
            const currentUserDoc = await getDoc(doc(db, "Users", currentUser.uid));
            const currentUserInfo = currentUserDoc.data();

            // Fetch friend's user info from Firestore
            console.log("userId", userId);
            const friendUserDoc = await getDoc(doc(db, "Users", userId));
            const friendUserInfo = friendUserDoc.data();

            const chatKey = `${currentUser.uid}_${userId}`;
            const chatData = {
                userId: currentUser.uid,
                friendId: userId,
                messages: [],
                timestamp: Date.now(),
            };

            // Firestore: Save chat data
            await setDoc(doc(db, "chats", chatKey), {
                members: [currentUser.uid, userId],
                lastMessage: "",
                timestamp: new Date(),
                Users: [
                    {
                        uid: currentUser.uid,
                        username: currentUserInfo?.profileInfo?.username || "",
                        profilePicUrl: currentUserInfo?.profileInfo?.profilePicUrl || "",
                        name: `${currentUserInfo?.profileInfo?.firstName || ""} ${currentUserInfo?.profileInfo?.lastName || ""}`,
                    },
                    {
                        uid: userId,
                        username: friendUserInfo?.profileInfo?.username || "",
                        profilePicUrl: friendUserInfo?.profileInfo?.profilePicUrl || "",
                        name: `${friendUserInfo?.profileInfo?.firstName || ""} ${friendUserInfo?.profileInfo?.lastName || ""}`,
                    }
                ]
            });

            // Firestore: Save chat data for each user
            await setDoc(doc(db, `Users/${currentUser.uid}/chats`, chatKey), {
                chatId: chatKey,
                timestamp: new Date(),
            });
            await setDoc(doc(db, `Users/${userId}/chats`, chatKey), {
                chatId: chatKey,
                timestamp: new Date(),
            });

            // Realtime Database: Save chat data
            await set(ref(rtdb, `chatMessages/${chatKey}`), chatData);

            // Navigate to chat page
            navigate('/chat');
        } catch (error) {
            console.error("Error creating chat:", error);
            toast.error(error.message )
        }
    };

    return (
        <>
            <div className="grid grid-cols-2 gap-4 px-2 py-4 mt-16" style={{ marginBottom: "20px" }}>
                <div className="flex flex-col items-center justify-center">
                    <img
                        className="object-cover border-4 border-indigo-600 rounded-full shadow-[5px_5px_0_0_rgba(0,0,0,1)] bg-indigo-50 h-48 w-48"
                        src={editor.profileInfo?.profilePicUrl}
                    />
                    <h1 className="text-2xl font-bold text-gray-500 mt-2">
                        @{editor.profileInfo?.username}
                    </h1>
                    <h2 className="text-xl font-bold">
                        {editor.profileInfo?.tagline}
                    </h2>
                    <ul className="flex flex-row mt-2 justify-center">
                        <li className="mx-2">
                            <a href={editor.socials?.instagram} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                                <FaInstagram className="h-6 w-6 text-indigo-700 hover:text-indigo-300" />
                            </a>
                        </li>
                        <li className="mx-2">
                            <a href={editor.socials?.youtube} target="_blank" rel="noopener noreferrer" aria-label="YouTube">
                                <FaYoutube className="h-6 w-6 text-indigo-700 hover:text-indigo-300" />
                            </a>
                        </li>
                        <li className="mx-2">
                            <a href={editor.socials?.twitch} target="_blank" rel="noopener noreferrer" aria-label="Twitch">
                                <FaTwitch className="h-6 w-6 text-indigo-700 hover:text-indigo-300" />
                            </a>
                        </li>
                        <li className="mx-2">
                            <a href={editor.socials?.twitter} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                                <FaTwitter className="h-6 w-6 text-indigo-700 hover:text-indigo-300" />
                            </a>
                        </li>
                        <li className="mx-2">
                            <a href={editor.socials?.tiktok} target="_blank" rel="noopener noreferrer" aria-label="TikTok">
                                <FaTiktok className="h-6 w-6 text-indigo-700 hover:text-indigo-300" />
                            </a>
                        </li>
                    </ul>
                    <button type="button" style={{ marginTop: "20px", backgroundColor: "rgb(251, 191, 36)" }} className="text-black font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2">{editor.profileInfo?.role}</button>
                </div>
                <div className="flex flex-col space-y-4">
                    <span className="bg-gray-200 font-bold p-2 rounded">
                        What things would make the editing gig the easiest for you? <br />
                        {editor.profileInfo.questions?.questionOne}
                    </span>
                    <span className="bg-gray-200 font-bold p-2 rounded">
                        How can you make the editing gig easiest for the other person? <br />
                        {editor.profileInfo.questions?.questionTwo}
                    </span>
                    <span className="bg-gray-200 font-bold p-2 rounded">
                        What are the pain points others may have while working with you?<br />
                        {editor.profileInfo.questions?.questionThree}
                    </span>
                    <span className="bg-gray-200 font-bold p-2 rounded">
                        What do you bring to the table?<br />
                        {editor.profileInfo.questions?.question}
                    </span>
                    <button type="button" onClick={createChat} style={{ marginTop: "20px", backgroundColor: "rgb(22, 163, 74)" }} className="text-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2">Message</button>
                </div>
            </div >
            <Dialog open={modalOpen} onClose={() => setModalOpen(false)} className="relative z-10">
                <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Enter the links to your top 10 most viewed videos
                                        </DialogTitle>
                                        <label> If you get reported for entering false videos you have not edited, you will be banned after a human review.</label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="videoOne"
                                                value={videoLinks.videoOne}
                                                onChange={handleInputChange}
                                                className="w-full border rounded p-2"
                                                placeholder="Video 1 URL"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="videoTwo"
                                                value={videoLinks.videoTwo}
                                                onChange={handleInputChange}
                                                className="w-full border rounded p-2"
                                                placeholder="Video 2 URL"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="videoThree"
                                                value={videoLinks.videoThree}
                                                onChange={handleInputChange}
                                                className="w-full border rounded p-2"
                                                placeholder="Video 3 URL"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="videoFour"
                                                value={videoLinks.videoFour}
                                                onChange={handleInputChange}
                                                className="w-full border rounded p-2"
                                                placeholder="Video 4 URL"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="videoFive"
                                                value={videoLinks.videoFive}
                                                onChange={handleInputChange}
                                                className="w-full border rounded p-2"
                                                placeholder="Video 5 URL"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="videoSix"
                                                value={videoLinks.videoSix}
                                                onChange={handleInputChange}
                                                className="w-full border rounded p-2"
                                                placeholder="Video 6 URL"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="videoSeven"
                                                value={videoLinks.videoSeven}
                                                onChange={handleInputChange}
                                                className="w-full border rounded p-2"
                                                placeholder="Video 7 URL"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="videoEight"
                                                value={videoLinks.videoEight}
                                                onChange={handleInputChange}
                                                className="w-full border rounded p-2"
                                                placeholder="Video 8 URL"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="videoNine"
                                                value={videoLinks.videoNine}
                                                onChange={handleInputChange}
                                                className="w-full border rounded p-2"
                                                placeholder="Video 9 URL"
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="videoTen"
                                                value={videoLinks.videoTen}
                                                onChange={handleInputChange}
                                                className="w-full border rounded p-2"
                                                placeholder="Video 10 URL"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    onClick={handleSaveVideos}
                                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                                >
                                    Save Videos
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setModalOpen(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            <div style={{ backgroundColor: "black" }}>
                <h3 className="text-2xl font-bold" style={{ color: "white", textAlign: "center", paddingTop: "3%" }}> {editor.profileInfo.username}'s Top 10 Most Viewed Videos</h3>
                <div className="grid grid-cols-5 gap-4 px-2 py-4 mt-4" style={{ paddingBottom: "6%" }}>
                    {videos
                        .sort((a, b) => b.statistics.viewCount - a.statistics.viewCount)
                        .map((video, index) => (
                            <a href={`https://www.youtube.com/watch?v=${video.id}`} target="_blank" rel="noopener noreferrer">
                                <div key={index} className="bg-white p-2 rounded shadow-lg" style={{ marginBottom: "10%" }}>
                                    <img
                                        src={video.snippet.thumbnails.medium.url}
                                        alt={video.snippet.title}
                                        className="w-full h-40 object-cover rounded"
                                    />
                                    <div className="mt-2">
                                        <h4 className="text-sm font-bold">{video.snippet.title}</h4>
                                        <p className="text-xs text-gray-600">
                                            {new Date(video.snippet.publishedAt).toLocaleDateString()}
                                        </p>
                                        <p className="text-xs text-gray-600">Views: {video.statistics.viewCount}</p>
                                        <p className="text-xs text-gray-600">Duration: {convertDuration(video.contentDetails.duration)}</p>
                                    </div>
                                </div>
                            </a>
                        ))}
                </div>
            </div>
        </>
    );
}