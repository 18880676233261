import React from "react";
import { Link } from "react-router-dom";
import TheNav from "../Components/navbar";
import { FaInstagram, FaYoutube, FaTwitch, FaTwitter } from "react-icons/fa";
import CreatorProfile from "../Components/editorProfileScreen";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "../Context/AuthContext";
import axios from "axios";
import ChannelVideos from "../Components/ChannelVideos";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { db } from "../Context/firebaseConfig";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import Loader from "../Components/Loader";
import useVideos from "../hooks/useVideos";

const CreatorPortfolio = () => {
    const { currentUser, logout, accessToken, setAccessToken } = useAuth();

    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { fetchVideos } = useVideos();

    useEffect(() => {
        if (currentUser && accessToken) {
            setLoading(true);
            fetchVideos(accessToken)
                .then((response) => {
                    setVideos(response);
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error.message);
                    setLoading(false);
                });
        }
    }, [accessToken, currentUser]);


    const handleGoogleLogin = useGoogleLogin({
        scope: "https://www.googleapis.com/auth/youtube.readonly",
        onSuccess: async (response) => {
            setAccessToken(response.access_token);
            await updateDoc(doc(db, "Users", currentUser.uid), {
                youtubeAccessToken: response.access_token,
            });

            if (response.access_token) {
                setAccessToken(response.access_token);
                await fetchVideos(response.access_token, true);
            }
        },
        onFailure: (error) => {
            setError(error.message);
            console.error("Google login failed:", error);
        },
    });

    return (
        <>
            <TheNav />
            <div className="flex flex-row justify-center items-center">
                <p
                    class="text-2xl text-gray-900 font-bold dark:text-white"
                    style={{ textAlign: "center", marginTop: "30px", marginBottom: "30px" }}
                >
                    Creator's Portfolio
                </p>
            </div>
            <CreatorProfile />
            {loading ? (
                <Loader />
            ) : error || !accessToken
                ? (
                    <div className="flex flex-col items-center justify-center">
                        <p className="text-center text-red-500 font-bold">{error}</p>
                        <button
                            onClick={handleGoogleLogin}
                            className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
                        >
                            Connect YouTube Account
                        </button>
                    </div>
                ) : (
                    <ChannelVideos videos={videos} />
                )}
        </>
    );
};

export default CreatorPortfolio;
