import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db, auth } from "../Context/firebaseConfig";
import { doc, onSnapshot, updateDoc, arrayUnion } from "firebase/firestore";

const ChatDetail = () => {
  const { conversationId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    if (!conversationId) return;

    const chatDoc = doc(db, "chats", conversationId);
    const unsubscribe = onSnapshot(chatDoc, (doc) => {
      if (doc.exists()) {
        setMessages(doc.data().messages || []);
      }
    });

    return () => unsubscribe();
  }, [conversationId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    const chatDoc = doc(db, "chats", conversationId);
    await updateDoc(chatDoc, {
      messages: arrayUnion({
        senderId: auth.currentUser.uid,
        text: newMessage,
        timestamp: new Date(),
      }),
    });

    setNewMessage("");
  };

  return (
    <div className="flex flex-col flex-auto h-full p-6">
      <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4">
        <div className="flex flex-col h-full overflow-x-auto mb-4">
          <div className="flex flex-col h-full">
            <div className="grid grid-cols-12 gap-y-2">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`col-start-${message.senderId === auth.currentUser.uid ? 6 : 1} col-end-${message.senderId === auth.currentUser.uid ? 13 : 8} p-3 rounded-lg`}
                >
                  <div className="flex flex-row items-center">
                    <div className={`flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0`}>
                      {message.senderId === auth.currentUser.uid ? "You" : "F"}
                    </div>
                    <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
                      <div>{message.text}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <form className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4" onSubmit={handleSendMessage}>
          <div className="flex-grow ml-4">
            <div className="relative w-full">
              <input
                type="text"
                className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                value={newMessage}
                onChange={(e) => setNewMessage(DOMPurify.sanitize(e.target.value))}
              />
            </div>
          </div>
          <div className="ml-4">
            <button type="submit" className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0">
              <span>Send</span>
              {/* <span className="ml-2">
                <svg className="w-4 h-4 transform rotate-45 -mt-px" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
                </svg>
              </span> */}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChatDetail;
