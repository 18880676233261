import React, { useState, useEffect } from "react";
import { Tabs } from "flowbite-react";
import { HiAdjustments, HiClipboardList, HiUserCircle } from "react-icons/hi";
import { MdDashboard } from "react-icons/md";
import { useParams } from "react-router-dom";
import { auth, db } from '../Context/firebaseConfig';
import { doc, getDoc } from "firebase/firestore";
import { loadStripe } from '@stripe/stripe-js';
import Loader from "../Components/Loader";
import OrderActions from "../Components/shared/OrderActions";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

export default function OrderDisplay() {
    const { orderNumber } = useParams(); // Order number from URL params
    const [orderData, setOrderData] = useState(null); // State to store order data
    const [error, setError] = useState(null); // State to handle errors
    const [basedButton, setBasedButton] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchOrderData = async () => {
            try {
                // Ensure the user is authenticated
                const currentUserId = auth.currentUser ? auth.currentUser.uid : null;

                if (!currentUserId) {
                    console.log("User is not authenticated");
                    setError("User is not authenticated");
                    return;
                }

                // Reference to the user's document
                const userDocRef = doc(db, "Users", currentUserId);
                const docSnap = await getDoc(userDocRef);

                if (docSnap.exists()) {
                    // Accessing the 'orders.current' map
                    const orders = docSnap.data().orders;

                    if (orders && orders.pending) {
                        const orderData =
                            orders.pending[orderNumber] ??
                            orders.current[orderNumber] ??
                            orders.previous[orderNumber];
                        // Get the specific order data

                        if (orderData) {
                            setOrderData(orderData); // Update state with order data
                            setLoading(false);
                        } else {
                            console.error("Order not found");
                            setError("Order not found");
                        }
                    } else {
                        console.error("No orders found");
                        setError("No orders found");
                    }
                } else {
                    console.error("User document not found");
                    setError("User document not found");
                    setLoading(false);
                }
            } catch (err) {
                console.error("Error fetching order data:", err);
                setError("Failed to fetch order data");
                setLoading(false);

            }
        };

        // Check authentication state and fetch data
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                fetchOrderData();
            } else {
                setError("User is not authenticated");
            }
        });

        return () => unsubscribe();
    }, [orderNumber]);

    useEffect(() => {
        if (orderData) {
            if (
                orderData.status === "pending" &&
                orderData.creatorId == auth.currentUser.uid
            ) {
                // Removed the Link component as it's not necessary for initiating the checkout
                setBasedButton(
                    <button onClick={handleCheckout}>Begin Order</button>
                );
            } else if (
                orderData.status === "progress" &&
                (orderData.creatorId === auth.currentUser.uid ||
                    orderData.creatorId !== auth.currentUser.uid)
            ) {
                setBasedButton(<button>In Progress</button>);
            }
            else if (orderData.status == "current") setBasedButton(<button>Current</button>);
        }
    }, [orderData, auth.currentUser]);

    const handleCheckout = async () => {
        try {
            const stripe = await stripePromise;
            const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    items: [
                        { id: orderNumber, title: orderData.title, price: orderData.price.final, quantity: 1, creatorId: orderData.creatorId, editorId: orderData.editorId },
                    ],
                }),
            });

            const data = await response.json();
            if (data.id) {
                const { error } = await stripe.redirectToCheckout({
                    sessionId: data.id,
                });
                if (error) {
                    console.error("Stripe checkout error:", error);
                }
            } else {
                console.error("Failed to create checkout session");
            }
        } catch (error) {
            console.error("Error during checkout:", error);
        }
    };


    return (
        loading ? <Loader /> :
            <>
                {error && (
                    <div className="text-red-600">
                        <p>{error}</p>
                    </div>
                )}
                {orderData && (
                    <div>
                        <Tabs aria-label="Default tabs" variant="default">
                            <Tabs.Item active title="Details" icon={HiUserCircle}>
                                <div>
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-base font-semibold leading-7 text-gray-900">Order Information</h3>
                                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Here is the information for the order.</p>
                                    </div>
                                    <div className="mt-6 border-t border-gray-100">
                                        <dl className="divide-y divide-gray-100">
                                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Order Title</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{orderData.title}</dd>
                                            </div>
                                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Order Description</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{orderData.description}</dd>
                                            </div>
                                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Initial Price</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">${orderData.price.initial}</dd>
                                            </div>
                                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Fees</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">${orderData.price.fees}</dd>
                                            </div>
                                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Final Price</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">${orderData.price.final}</dd>
                                            </div>
                                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Due Date</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{orderData.dueDate}</dd>
                                            </div>
                                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Status</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> {basedButton}</dd>
                                            </div>
                                            {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                            <dt className="text-sm font-medium leading-6 text-gray-900">Status</dt>
                                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                {orderData.status}
                                            </dd>
                                            <div className="mt-1 sm:col-span-2">
                                                {basedButton}
                                            </div>
                                        </div> */}
                                        </dl>
                                    </div>
                                </div>
                            </Tabs.Item>
                            {/* <Tabs.Item title="Requirements" icon={MdDashboard}>
                                This is <span className="font-medium text-gray-800 dark:text-white">Requirements tab's associated content</span>.
                            </Tabs.Item> */}
                            <Tabs.Item title="Delivery" icon={HiAdjustments}>
                                This is <span className="font-medium text-gray-800 dark:text-white">Delivery tab's associated content</span>.
                                <OrderActions
                                    orderData={orderData}
                                    orderNumber={orderNumber}
                                />
                            </Tabs.Item>
                        </Tabs>
                    </div>
                )}
            </>
    );
}
