import React, { useState, useEffect } from 'react';
import { useAuth } from '../Context/AuthContext';
import { db } from '../Context/firebaseConfig';
import { doc, getDoc, collection, getDocs, query, orderBy, onSnapshot } from 'firebase/firestore';
import { getDatabase, ref, onValue } from 'firebase/database';
import ChatSidebar from '../Components/ChatSidebar';
import ChatWindow from '../Components/ChatWindow';
import TheNav from "../Components/navbar"

const ChatPage = () => {
    const { currentUser } = useAuth();
    const [selectedChat, setSelectedChat] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [messages, setMessages] = useState([]);
    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        const fetchConversations = async () => {
            const db = getDatabase();
            const chatRef = ref(db, 'chatMessages');
            onValue(chatRef, (snapshot) => {
                const chatList = [];
                snapshot.forEach((childSnapshot) => {
                    const chatId = childSnapshot.key;
                    const chatData = childSnapshot.val();
                    chatList.push({ id: chatId, ...chatData });
                });
                setConversations(chatList);
            });
        };

        if (currentUser) {
            fetchConversations();
        }
    }, [currentUser]);

    useEffect(() => {
        const fetchMessages = async () => {
            if (selectedChat) {
                const db = getDatabase();
                const chatRef = ref(db, `chatMessages/${selectedChat}/messages`);
                onValue(chatRef, (snapshot) => {
                    const messagesList = [];
                    snapshot.forEach((childSnapshot) => {
                        messagesList.push(childSnapshot.val());
                    });
                    setMessages(messagesList);
                });
            }
        };

        fetchMessages();
    }, [selectedChat]);

    const fetchUserDetails = async (userId) => {
        const userDoc = doc(db, 'Users', userId);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
            return userSnapshot.data();
        }
        return null;
    };

    useEffect(() => {
        const fetchAllUserDetails = async () => {
            const details = {};
            for (const conversation of conversations) {
                const friendId = conversation.userId === currentUser.uid ? conversation.friendId : conversation.userId;
                if (!details[friendId]) {
                    const userDetails = await fetchUserDetails(friendId);
                    if (userDetails) {
                        details[friendId] = userDetails;
                    }
                }
            }
            setUserDetails(details);
        };

        if (conversations.length > 0) {
            fetchAllUserDetails();
        }
    }, [conversations, currentUser]);

    return (
        <>
            <TheNav />
            <div className="flex h-screen antialiased text-gray-800">
                <div className="flex flex-row h-full w-full overflow-x-hidden">
                    <ChatSidebar conversations={conversations} onSelectConversation={setSelectedChat} />
                    {selectedChat && (
                        <ChatWindow
                            messages={messages}
                            currentUser={currentUser}
                            userDetails={userDetails}
                            selectedChat={selectedChat} // Pass selectedChat to ChatWindow
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default ChatPage;
