import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../Context/AuthContext";
import Loader from "../Loader";
import axios from "axios";
import OrderDelivery from "../creator/OrderDelivery";
import sanitizeFilename from "sanitize-filename";

const CircularLoader = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-[#1f2937]"></div>
    </div>
  );
};

const OrderActions = ({ orderData, orderNumber }) => {
  const { activeUser } = useAuth(); // Assuming you have a context for auth
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const fileRef = useRef(null);
  const role = activeUser?.profileInfo?.role;

  const uploadFile = async (file) => {
    const formData = new FormData();

    formData.append("file", file);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/file/upload`,
        formData,
        config
      );
      console.log("File uploaded successfully", response);
      setUploadedFiles([...uploadedFiles, response.data]);
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const sanitizedFileName = sanitizeFilename(file.name);
      const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });
      await uploadFile(sanitizedFile);
    }
  };

  const downloadFile = async (fileLink) => {
    try {
      //open the file in a new tab
      window.open(fileLink, "_blank");
    } catch (error) {
      console.error("Error downloading file", error);
    }
  };

  const deliverOrder = async () => {
    try {
      //user would send the order details in the request body
      //includes creatorId, editorId, orderNumber, and files to be added to the order

      setSubmitting(true);
      //send the order details to the server
      const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/submit-order`, {
        creatorId: orderData.creatorId,
        editorId: orderData.editorId,
        orderNumber: orderNumber,
        files: uploadedFiles,
      });

      console.log("Order submitted successfully", response);
      setSubmitting(false);
    } catch (error) {
      console.error("Error submitting order", error);
      setSubmitting(false);
    }
  };

  if (!activeUser) return <Loader />;

  return (
    <div className="m-2">
      {role.toLowerCase() === "editor" ? (
        //if order has status approved then show the text only
        orderData.status === "approved" ? (
          <div className="flex flex-col w-full text-left">
            <p className="text-left text-base font-semibold">
              Order has been approved by the creator
            </p>
            <div className="flex justify-start flex-row flex-wrap gap-x-4">
              {orderData?.files?.map((file) => (
                <div
                  key={file.id}
                  className="border-2 p-2 my-2 rounded-md cursor-pointer"
                  onClick={() => downloadFile(file.publicUrl)}
                >
                  <p className="text-sm">{file.name}</p>
                  <p className="text-xs">{file.size}</p>
                  <p className="text-xs">{file.type}</p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-full text-left">
            <div
              className="text-center max-w-[400px] border-2 rounded-md border-[#1f2937] p-3 cursor-pointer"
              onClick={() => fileRef.current.click()}
            >
              <input
                type="file"
                accept=".mp4, .mov"
                ref={fileRef}
                className="hidden"
                onChange={handleFileChange}
              />

              {loading ? (
                <CircularLoader />
              ) : (
                <img
                  src="/upload.svg"
                  alt="upload"
                  className="w-14 h-14 mx-auto"
                />
              )}
              <p className="text-lg text-center">
                Upload the final version of the order
              </p>
            </div>

            <div className="flex justify-start flex-row flex-wrap gap-x-4">
              {uploadedFiles?.map((file) => (
                <div
                  key={file.id}
                  className="border-2 p-2 my-2 rounded-md cursor-pointer"
                  onClick={() => downloadFile(file.publicUrl)}
                >
                  <p className="text-sm">{file.name}</p>
                  <p className="text-xs">{file.size}</p>
                  <p className="text-xs">{file.type}</p>
                </div>
              ))}
            </div>
            <button
              className="bg-[#1f2937] text-white p-2 rounded mt-2 w-max "
              disabled={loading || submitting || uploadedFiles.length === 0}
              onClick={deliverOrder}
            >
              {submitting ? "Submitting..." : "Submit Order"}
            </button>
          </div>
        )
      ) : (
        <div className="text-left">
          <OrderDelivery orderDetails={orderData} />
        </div>
      )}
    </div>
  );
};

export default OrderActions;
