import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../Context/firebaseConfig";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getUserToken } from "../api/user";
import Ronaldo from "../images/Stable.jpg";
import Logo from "../images/logo.png";
import DOMPurify from "dompurify";

export default function Login() {
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate("/");
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // setFormData(prevFormData => ({
        //     ...prevFormData,
        //     [name]: value,
        // }));
        const sanitizedValue = DOMPurify.sanitize(value);

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: sanitizedValue,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = formData;
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            await getUserToken(userCredential.user);
            navigate("/");
        } catch (error) {
            alert("Error logging in: " + error.message);
        }
    };

    return (
        <>
            <header>
                <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
                    Get 10% off your first order for a successful referral (purchase)
                </p>
            </header>
            <div
                className="flex min-h-screen bg-gray-100"
                style={{
                    backgroundImage: `url(${Ronaldo})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-lg lg:w-96 bg-white p-8 rounded-lg shadow-lg">
                        <div>
                            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 text-center">
                                Login
                            </h2>
                            <img
                                alt=""
                                src={Logo}
                                className="mx-auto lg:w-60  p-8"
                            />
                            <p className="mt-2 text-sm text-gray-600 text-center">
                                Not a Kre8 member?{' '}
                                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                    <Link to="/signup"> Signup here </Link>
                                </a>
                            </p>
                        </div>

                        <div className="mt-8">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email address
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            autoComplete="email"
                                            required
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Password
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            autoComplete="current-password"
                                            required
                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="text-sm">
                                        <h2 href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                                            <Link to="/reset"> Forgot password? </Link>
                                        </h2>
                                    </div>
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        style={{ backgroundColor: 'indigo' }}
                                    >
                                        Sign in
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
