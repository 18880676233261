import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { auth } from './firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { getUserToken, getUserDetails } from "../api/user";

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [accessToken, setAccessToken] = useState(null);
  const [activeUser, setActiveUser] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
      if (user) {
        getUserToken(user).then((token) => {
          setAccessToken(token);
        });
        getUserDetails(user).then((user) => {
          setActiveUser(user);
        });
      }
    })
    return () => unsubscribe()
  }, [])

  const logout = () => {
    signOut(auth).then(() => {
    }).catch((error) => {
      console.error('Logout Error:', error)
    })
  }
  

  const value = {
    currentUser,
    logout,
    accessToken,
    setAccessToken,
    activeUser,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}
