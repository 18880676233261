import React from "react";
import TheNav from "../Components/navbar";
import CreatorProfileScreen from "../Components/viewCreatorProfileScreen";
import { useParams } from 'react-router';

const CreatorPortfolio = () => {
    const { creatorId } = useParams();
    return (
        <>
            <TheNav />
            <CreatorProfileScreen creatorName={creatorId}/>
        </>
    );
};

export default CreatorPortfolio;
