import { BrowserRouter, Routes, Route } from "react-router-dom";
import './CSS/tailwind.css';
import Signup from "./Pages/signup";
import Login from "./Pages/login";
import EditorPortfolio from "./Pages/editorPortfolio";
import CreatorPortfolio from "./Pages/creatorPortfolio";
import ViewAvailableEditors from "./Pages/viewAvailableEditors";
import ViewAvailableCreators from "./Pages/viewAvailableCreators";
import EditorSettings from "./Pages/editorSettings";
import CreatorSettings from "./Pages/creatorSettings";
import ResetPassword from "./Pages/resetPassword";
import ChatDetail from "./Pages/ChatDetail";
import GameOfChoice from "./Pages/gameOfChoice";
import ChatPage from './Pages/ChatPage';
import Main from "./Pages/main";
import { AuthProvider } from "./Context/AuthContext";
import OrderDisplay from "./Pages/OrderDisplay";
import CreateNewCategory from "./Pages/CreateNewCategory";
import Orders from "./Pages/Orders";
import ViewCreatorProfile from "./Pages/viewCreatorProfile";
import ViewEditorProfile from "./Pages/viewEditorProfile";
import useNotificationPermission from "./hooks/useNotificationPermission";
import Cookies from './T&C/cookies';
import Terms from './T&C/terms';
import Privacy from './T&C/privacy';

export default function App() {
      useNotificationPermission();
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/chat" element={<ChatPage />} />
          <Route path="/chat/:conversationId" element={<ChatDetail />} />
          <Route path="/editorSettings" element={<EditorSettings />} />
          <Route path="/creatorSettings" element={<CreatorSettings />} />
          <Route path="/editors"element={<ViewAvailableEditors />} />
          <Route path="/chooseGame" element={<GameOfChoice />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/editors" element={<ViewAvailableEditors/>}/>
          <Route path="/creators" element={<ViewAvailableCreators/>}/>
          <Route path="/order/:orderNumber" element={<OrderDisplay />} />
          <Route path="/creatorPortfolio" element={<CreatorPortfolio />} />
          <Route path="/editorPortfolio" element={<EditorPortfolio/>} />
          <Route path="/createCategory" element={<CreateNewCategory />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/creator/:creatorId" element={<ViewCreatorProfile />} />
          <Route path="/editor/:editorId" element={<ViewEditorProfile />} />
          <Route path="/legal/cookies" element={<Cookies />} />
          <Route path="/legal/terms" element={<Terms />} />
          <Route path="/legal/policy" element={<Privacy />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}